import { LandingPageListResponseDataItem } from "@/typings/api"
import { desktopThreshold } from "@/utils/theme"
import { Box, HStack, VStack, useMediaQuery, Stack } from "@chakra-ui/react"
import Carousel from "./Carousel/Carousel"
import PresentationBlock from "./Landing/PresentationBlock"
import AuthActions from "./Navigation/AuthActions"

const PublicHome = ({
  landingData,
}: {
  landingData: LandingPageListResponseDataItem
}) => {
  const [isDesktop] = useMediaQuery(`(min-width: ${desktopThreshold})`)
  const Presentation1Data = landingData?.attributes?.presentation_1
  const Presentation2Data = landingData?.attributes?.presentation_2
  const Presentation3Data = landingData?.attributes?.presentation_3
  const CarouselData = landingData?.attributes?.carousel

  return (
    <Box as="main" width="100%" bgColor="white" fontFamily="GTMaru">
      <HStack spacing={0} bgColor="primaryColor">
        <Stack
          width="100%"
          flexDir={{ base: "column", lg: "row" }}
          alignItems="center"
          padding={2}
          position="relative"
        >
          <Carousel data={CarouselData} />
        </Stack>
      </HStack>
      {!isDesktop && (
        <VStack spacing={5} p={5} bgColor="primaryColor">
          <AuthActions />
        </VStack>
      )}
      <PresentationBlock
        index={0}
        imageUrl={Presentation1Data?.image?.data?.attributes?.url}
        text={Presentation1Data?.description}
        altText={Presentation1Data?.image?.data?.attributes?.alternativeText}
      />
      <PresentationBlock
        index={1}
        imageUrl={Presentation2Data?.image?.data?.attributes?.url}
        text={Presentation2Data?.description}
        altText={Presentation2Data?.image?.data?.attributes?.alternativeText}
      />
      <PresentationBlock
        index={0}
        imageUrl={Presentation3Data?.image?.data?.attributes?.url}
        text={Presentation3Data?.description}
        altText={Presentation3Data?.image?.data?.attributes?.alternativeText}
        hasButton={false}
      />
    </Box>
  )
}

export default PublicHome
