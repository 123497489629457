import PageDivider from "@/components/PageDivider"
import {
  Box,
  Card,
  HStack,
  Skeleton,
  SkeletonCircle,
  VStack,
} from "@chakra-ui/react"

const GroupCardSkeleton = () => {
  return (
    <Box padding={4}>
      <Card bg="white" borderRadius="sm" boxShadow="lg" p={4} height="100%">
        <VStack spacing={2} alignItems="flex-start">
          <Skeleton height="25px" width="60%" />
          <PageDivider opacity={0.4} />
          <Skeleton height="200px" width="100%" />
          <PageDivider opacity={0.4} />
          <HStack justifyContent="space-between" width="100%">
            <HStack>
              {Array.from(Array(3).keys()).map((el) => (
                <SkeletonCircle height="40px" width="40px" key={el} />
              ))}
            </HStack>

            <Skeleton height="25px" width="20%" />
          </HStack>
        </VStack>
      </Card>
    </Box>
  )
}

export default GroupCardSkeleton
