const BooksEmpty = (props: any) => {
  return (
    <svg
      width="596"
      height="9"
      viewBox="0 0 596 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <line
        x1="591.5"
        y1="4.5"
        x2="4.5"
        y2="4.5"
        stroke="black"
        strokeWidth="9"
        strokeLinecap="square"
      />
    </svg>
  )
}

export default BooksEmpty
