const BooksSm = (props: any) => {
  return (
    <svg
      width="596"
      height="243"
      viewBox="0 0 596 243"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <line
        x1="591.5"
        y1="238.5"
        x2="4.5"
        y2="238.5"
        stroke="black"
        strokeWidth="9"
        strokeLinecap="square"
      />
      <path d="M57.3014 35.7573H14V237.565H57.3014V35.7573Z" fill="black" />
      <path
        d="M103.589 71.6343H60.2881V237.565H103.589V71.6343Z"
        fill="black"
      />
      <path d="M130 60H107V238H130V60Z" fill="black" />
    </svg>
  )
}

export default BooksSm
