import { getImageUrl } from "@/utils/images"
import { Avatar, HStack, Text } from "@chakra-ui/react"
import Link from "next/link"

const NewsListItem = ({
  id,
  url,
  title,
}: {
  id?: number
  url?: string
  title?: string
}) => {
  return (
    <Link href={`/news/${id}`}>
      <HStack bg="white" boxShadow="lg" p={2} height="100%">
        <Avatar src={getImageUrl(url)} />
        <Text
          fontSize="sm"
          textAlign="left"
          wordBreak="break-word"
          noOfLines={4}
          fontFamily="GTMaru"
          fontWeight="bold"
        >
          {title}
        </Text>
      </HStack>
    </Link>
  )
}

export default NewsListItem
