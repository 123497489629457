import { Box, Button, Text, VStack } from "@chakra-ui/react"
import { useRouter } from "next/router"
import { useTranslation } from "next-i18next"

const TextBlock = ({
  description,
  hasButton = true,
}: {
  description?: string
  hasButton?: boolean
}) => {
  const router = useRouter()
  const { t } = useTranslation()

  return (
    <VStack flex={1} justifyContent="center">
      <Box paddingBottom={4}>
        <Text textAlign="center" fontSize="lg" fontFamily="GTAlpina">
          {description}
        </Text>
      </Box>

      {hasButton && (
        <Button variant="brand" onClick={() => router.push("/auth/register")}>
          {t("home.create_account")}
        </Button>
      )}
    </VStack>
  )
}
export default TextBlock
