import { Circle, SquareProps } from "@chakra-ui/react"
import React from "react"
import { useTranslation } from "next-i18next"
import CustomTooltip from "../Buttons/CustomTooltip"

const NotificationBadge = ({
  count,
  ...props
}: { count: number } & SquareProps) => {
  const { t } = useTranslation()
  return (
    <CustomTooltip
      label={t("global.notification_count", { count })}
      placement="top"
    >
      <Circle
        cursor="help"
        fontWeight="bold"
        fontSize="sm"
        backgroundColor="primaryColor"
        size="2.2rem"
        {...props}
      >
        {count}
      </Circle>
    </CustomTooltip>
  )
}

export default NotificationBadge
