const BooksLg = (props: any) => {
  return (
    <svg
      width="596"
      height="208"
      viewBox="0 0 596 208"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <line
        x1="591.5"
        y1="203.5"
        x2="4.5"
        y2="203.5"
        stroke="black"
        strokeWidth="9"
        strokeLinecap="square"
      />
      <path
        d="M454.209 22.3845L426.687 33.5171L495.486 203.998L523.008 192.865L454.209 22.3845Z"
        fill="black"
      />
      <path d="M57.3014 0.757324H14V202.565H57.3014V0.757324Z" fill="black" />
      <path
        d="M103.589 36.6343H60.2881V202.565H103.589V36.6343Z"
        fill="black"
      />
      <path d="M303.671 18.6958H260.37V202.565H303.671V18.6958Z" fill="black" />
      <path
        d="M424.616 18.6958H406.698V202.565H424.616V18.6958Z"
        fill="black"
      />
      <path
        d="M452.986 101.661H427.603V202.565H452.986V101.661Z"
        fill="black"
      />
      <path
        d="M149.877 36.6343H106.575V202.565H149.877V36.6343Z"
        fill="black"
      />
      <path
        d="M176.754 56.0674H152.863V202.565H176.754V56.0674Z"
        fill="black"
      />
      <path
        d="M357.425 56.0674H333.534V202.565H357.425V56.0674Z"
        fill="black"
      />
      <path d="M203.63 36.6343H179.739V202.565H203.63V36.6343Z" fill="black" />
      <path
        d="M403.712 3.74707H360.411V202.565H403.712V3.74707Z"
        fill="black"
      />
      <path
        d="M330.548 36.6343H306.657V202.565H330.548V36.6343Z"
        fill="black"
      />
      <path
        d="M257.383 14.2109H206.616V202.565H257.383V14.2109Z"
        fill="black"
      />
    </svg>
  )
}

export default BooksLg
