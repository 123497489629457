import { useToast, UseToastOptions } from "@chakra-ui/react"

const useAppToast = () => {
  const toast = useToast()

  return (options: UseToastOptions) =>
    toast({
      duration: 3000,
      isClosable: true,
      position: "top-right",
      status: "success",
      ...options,
    })
}

export default useAppToast
