import BooksEmpty from "@/svg/booksEmpty"
import BooksLg from "@/svg/booksLg"
import BooksMd from "@/svg/booksMd"
import BooksSm from "@/svg/booksSm"
import BooksXl from "@/svg/booksXl"
import { Box, Flex } from "@chakra-ui/react"

const GroupIllustration = ({ books, isHalfPrivate }: { books: number, isHalfPrivate?: boolean }) => {
  const getIllustration = () => {
    if (books === 0)
      return (
        <Box paddingBottom="10px">
          <BooksEmpty width="100%" />
        </Box>
      )
    if (books < 5) return <BooksSm width="100%" height="100%" />
    if (books < 10) return <BooksMd width="100%" height="100%" />
    if (books < 15) return <BooksLg width="100%" height="100%" />
    return <BooksXl width="100%" height="100%" />
  }

  return (
    <Flex height="150px" alignItems="end" bg={isHalfPrivate ? 'primaryColor' : ''}>
      {getIllustration()}
    </Flex>
  )
}

export default GroupIllustration
