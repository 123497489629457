import { getImageUrl } from "@/utils/images"
import { Center, Container, Image, Stack } from "@chakra-ui/react"
import TextBlock from "./TextBlock"

const PresentationBlock = ({
  index,
  imageUrl,
  altText,
  text,
  hasButton,
}: {
  index: number
  imageUrl?: string
  text?: string
  altText?: string
  hasButton?: boolean
}) => {
  const isEven = index % 2 === 0
  if (!imageUrl && !text) return null
  return (
    <Center
      _even={{ bg: "secondary.light" }}
      paddingY={{ base: 16, md: 10 }}
      minH="60vh"
    >
      <Container maxWidth="container.lg">
        <Stack
          flexDir={{
            base: isEven ? "column" : "column-reverse",
            md: isEven ? "row" : "row-reverse",
          }}
          spacing={4}
        >
          <TextBlock description={text} hasButton={hasButton} />

          <Center flex={1}>
            <Image
              src={getImageUrl(imageUrl)}
              alt={altText}
              fit="cover"
              maxH="70vh"
              padding={10}
            />
          </Center>
        </Stack>
      </Container>
    </Center>
  )
}

export default PresentationBlock
