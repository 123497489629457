import { Card, Stack, Skeleton, VStack } from "@chakra-ui/react"

const NewsListCardSkeleton = () => {
  return (
    <Card bg="white" borderRadius="sm" boxShadow="lg" p={4} h="100%" w="100%">
      <Stack alignItems="flex-start" flexDir={{ base: "column", sm: "row" }}>
        <Skeleton height="40px" width="40px" rounded="full" />
        <VStack spacing={4} flex="1" alignItems="flex-start" paddingLeft={2}>
          <Skeleton height="25px" width="60%" />
          <Skeleton height="25px" width="60%" />
        </VStack>
      </Stack>
    </Card>
  )
}
export default NewsListCardSkeleton
