import client from "@/api/client"
import { SimpleGrid } from "@chakra-ui/react"
import { useTranslation } from "next-i18next"
import { useQuery } from "react-query"
import NewsListItem from "./NewsListItem"
import NewsListCardSkeleton from "./Skeletons/NewsListCardSkeleton"
import PageHeader from "../Layout/PageHeader"
import ContainerPage from "../Layout/ContainerPage"

const NewsList = () => {
  const { t } = useTranslation()

  const { data, isLoading } = useQuery("get-articles", () =>
    client.articles.getArticles({
      //@ts-ignore
      query: {
        populate: "*",
      },
      "populate[cover]": "*",
      "pagination[page]": 0,
      "pagination[pageSize]": 6,
      sort: "publishedAt:desc",
    })
  )

  const news = data?.data?.data || []

  return (
    <>
      {news.length > 0 && (
        <>
          <PageHeader
            title={t("group.news.my_news.title")}
            breadcrumbItems={[
              {
                label: "Home",
              },
            ]}
          />
          <ContainerPage>
            {isLoading && !news && <NewsListCardSkeleton />}
            {!isLoading && !!news?.length && (
              <SimpleGrid columns={{ base: 1, md: 3 }} mb={6} gap={4}>
                {news?.map((item, index) => (
                  <NewsListItem
                    key={index}
                    title={item?.attributes?.title}
                    url={item?.attributes?.cover?.data?.attributes?.url}
                    id={item?.id}
                  />
                ))}
              </SimpleGrid>
            )}
          </ContainerPage>
        </>
      )}
    </>
  )
}
export default NewsList
