import Library from "@/svg/library"
import { HStack, Text } from "@chakra-ui/react"
import { useTranslation } from "next-i18next"
import CustomTooltip from "../Buttons/CustomTooltip"

const GroupReviews = ({ books }: { books: number }) => {
  const { t } = useTranslation()
  return (
    <CustomTooltip label={t("group.review_helper")}>
      <HStack spacing={0}>
        <Library height="20px" width="31px" />
        <Text fontFamily="GTMaru">{books ?? 0}</Text>
      </HStack>
    </CustomTooltip>
  )
}

export default GroupReviews
