import { GroupListResponseDataItem } from "@/typings/api"
import {
  Box,
  Center,
  HStack,
  Modal,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import { PropsWithChildren } from "react"
import { useTranslation } from "next-i18next"
import useJoinGroup from "@/hooks/useJoinGroup"
import { useRouter } from "next/router"

const JoinGroupModal = ({
  children,
  group,
}: PropsWithChildren & { group: GroupListResponseDataItem }) => {
  const { t } = useTranslation()
  const { isOpen, onClose, onOpen } = useDisclosure()
  const router = useRouter()

  const { mutate: joinGroup } = useJoinGroup({
    onSuccess: () => {
      onClose()
      router.push(`/groups/${group.id}`)
    },
  })
  return (
    <>
      <Box onClick={onOpen}>{children}</Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <Stack
            spacing={3}
            height="min-content"
            bg="primaryColor"
            p={4}
            paddingY={10}
          >
            <Box>
              <Text fontSize="lg" textAlign="center" fontFamily="GTMaru">
                {t("join_group.title")}
              </Text>
            </Box>
            <Box>
              <Text fontSize="sm" textAlign="center" fontFamily="GTMaru">
                {t("join_group.description")}
              </Text>
            </Box>
            <Box>
              <Text fontSize="xs" textAlign="center" fontFamily="GTMaru">
                {t("join_group.nota_bene")}
              </Text>
            </Box>
            <HStack alignItems="normal">
              <Center
                cursor="pointer"
                backgroundColor="black"
                color="white"
                fontFamily="GTMaru"
                fontSize="sm"
                p={1}
                onClick={onClose}
                width="100%"
                textAlign="center"
              >
                {t("global.cancel")}
              </Center>
              <Center
                cursor="pointer"
                backgroundColor="black"
                color="white"
                fontFamily="GTMaru"
                fontSize="sm"
                p={1}
                width="100%"
                textAlign="center"
                onClick={() => joinGroup({ group: group.id })}
              >
                {t("join_group.validate", { name: group?.attributes?.name })}
              </Center>
            </HStack>
          </Stack>
        </ModalContent>
      </Modal>
    </>
  )
}

export default JoinGroupModal
