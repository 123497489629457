import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  HStack,
  Stack,
  Text,
  TextProps,
} from "@chakra-ui/react"
import Head from "next/head"
import { ReactNode } from "react"
import ContainerPage from "./ContainerPage"
import Link from "next/link"

const PageHeader = ({
  title,
  extraActions,
  breadcrumbItems = [],
  descriptionGroup,
  ...props
}: {
  title: string
  extraActions?: ReactNode
  descriptionGroup?: string
  breadcrumbItems?: {
    label: string
    href?: string
  }[]
} & TextProps) => {
  return (
    <>
      <Head>
        <title>{title}</title>
      </Head>
      {breadcrumbItems.length > 0 && (
        <Breadcrumb
          p={2}
          px={{ base: 2, sm: 6 }}
          position="sticky"
          top={0}
          zIndex={100}
          overflow="scroll"
          bg="white"
          borderBottomWidth={1}
          borderBottomColor="blackAlpha.300"
          flexWrap="nowrap"
        >
          {breadcrumbItems?.map((item) => (
            <BreadcrumbItem key={item.href}>
              {item.href ? (
                <BreadcrumbLink
                  as={Link}
                  fontSize="sm"
                  href={item.href}
                  fontFamily="GTMaru"
                >
                  {item.label}
                </BreadcrumbLink>
              ) : (
                <Text
                  noOfLines={1}
                  fontSize="sm"
                  fontFamily="GTMaru"
                  fontWeight="bold"
                >
                  {item.label}
                </Text>
              )}
            </BreadcrumbItem>
          ))}
        </Breadcrumb>
      )}

      <Box paddingBottom={4}>
        <ContainerPage>
          <Stack
            flexDirection={{
              base: "column",
              sm: "column",
              md: "row",
              lg: "row",
            }}
            justifyContent="space-between"
            paddingBottom={2}
            borderBottom="1px solid black"
          >
            <HStack>
              <Text
                fontFamily="GTMaru"
                fontWeight="bold"
                fontSize="xl"
                wordBreak="break-word"
                {...props}
              >
                {title}
              </Text>
              {descriptionGroup}
            </HStack>
            {extraActions}
          </Stack>
        </ContainerPage>
      </Box>
    </>
  )
}

export default PageHeader
