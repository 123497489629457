const BooksXl = (props: any) => {
  return (
    <svg
      width="596"
      height="243"
      viewBox="0 0 596 243"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <line
        x1="591.5"
        y1="238.5"
        x2="4.5"
        y2="238.5"
        stroke="black"
        strokeWidth="9"
        strokeLinecap="square"
      />
      <path d="M57.3014 35.7573H14V237.565H57.3014V35.7573Z" fill="black" />
      <path
        d="M103.589 71.6343H60.2881V237.565H103.589V71.6343Z"
        fill="black"
      />
      <path d="M303.671 53.6958H260.37V237.565H303.671V53.6958Z" fill="black" />
      <path
        d="M424.616 53.6958H406.698V237.565H424.616V53.6958Z"
        fill="black"
      />
      <path
        d="M452.986 136.661H427.603V237.565H452.986V136.661Z"
        fill="black"
      />
      <path
        d="M442.414 17.8184H306.657V35.7568H442.414V17.8184Z"
        fill="black"
      />
      <path d="M585 220H483V238H585V220Z" fill="black" />
      <path d="M585 125H483V143H585V125Z" fill="black" />
      <path d="M585 183H488V201H585V183Z" fill="black" />
      <path d="M573 170H483V180H573V170Z" fill="black" />
      <path d="M573 146H503V167H573V146Z" fill="black" />
      <path d="M591 204H498V217H591V204Z" fill="black" />
      <path d="M466.305 0H330.548V14.8291H466.305V0Z" fill="black" />
      <path
        d="M149.877 71.6343H106.575V237.565H149.877V71.6343Z"
        fill="black"
      />
      <path
        d="M176.754 91.0674H152.863V237.565H176.754V91.0674Z"
        fill="black"
      />
      <path
        d="M357.425 91.0674H333.534V237.565H357.425V91.0674Z"
        fill="black"
      />
      <path d="M203.63 71.6343H179.739V237.565H203.63V71.6343Z" fill="black" />
      <path
        d="M403.712 38.7471H360.411V237.565H403.712V38.7471Z"
        fill="black"
      />
      <path
        d="M224.386 19.9343L60.6865 45.8916L64.4238 69.5151L228.123 43.5578L224.386 19.9343Z"
        fill="black"
      />
      <path
        d="M203.177 4.91829L58.709 27.8262L61.0495 42.6204L205.518 19.7125L203.177 4.91829Z"
        fill="black"
      />
      <path
        d="M285.079 25.4568L278.583 35.8647L351.849 81.6992L358.345 71.2912L285.079 25.4568Z"
        fill="black"
      />
      <path
        d="M488.076 37.0005L471 44.8965L507.191 123.375L524.267 115.479L488.076 37.0005Z"
        fill="black"
      />
      <path
        d="M509.511 46.2639L498.07 51.5542L531.191 123.375L542.632 118.085L509.511 46.2639Z"
        fill="black"
      />
      <path
        d="M330.548 71.6343H306.657V237.565H330.548V71.6343Z"
        fill="black"
      />
      <path d="M479.89 71.6343H456V237.565H479.89V71.6343Z" fill="black" />
      <path
        d="M257.383 49.2109H206.616V237.565H257.383V49.2109Z"
        fill="black"
      />
    </svg>
  )
}

export default BooksXl
