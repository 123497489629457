import { InfoOutlineIcon } from "@chakra-ui/icons"
import { Card, CardProps, Text, VStack } from "@chakra-ui/react"
import { ReactNode } from "react"

const NoListContent = ({
  info,
  children,
  ...props
}: {
  info: string
  children?: ReactNode
} & CardProps) => {
  return (
    <Card boxShadow="lg" textAlign="center" bg="white" p={2} {...props}>
      <VStack minHeight="150px" justifyContent="space-evenly">
        <InfoOutlineIcon boxSize={10} />
        <Text fontFamily="GTMaru">{info}</Text>
        {children}
      </VStack>
    </Card>
  )
}

export default NoListContent
