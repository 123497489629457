import { PagePhotoComponent } from "@/typings/api"
import {
  Box,
  Center,
  Container,
  Flex,
  HStack,
  Image,
  Text,
  useMediaQuery,
} from "@chakra-ui/react"
import { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import { getImageUrl } from "@/utils/images"
import { desktopThreshold } from "@/utils/theme"
import "swiper/css"
import "swiper/css/pagination"
import SwipperButton from "./SwipperButton"

type Props = {
  data: PagePhotoComponent[] | undefined
}

const Carousel = ({ data }: Props) => {
  const [isDesktop] = useMediaQuery(`(min-width: ${desktopThreshold})`)

  return (
    <Container maxWidth="container.xl" paddingX={{ base: 0, md: 4 }}>
      <HStack>
        <SwipperButton action="prev" isPublicHome hide={!isDesktop} />
        <Swiper
          navigation={{
            nextEl: ".image-swiper-button-next",
            prevEl: ".image-swiper-button-prev",
            disabledClass: "swiper-button-disabled",
          }}
          slidesPerView={1}
          slidesPerGroup={1}
          modules={[Navigation]}
        >
          {data?.map((item, index) => {
            const url = getImageUrl(item.image?.data?.attributes?.url)
            const alt = item.image?.data?.attributes?.alternativeText
            const isVideo =
              item.image?.data?.attributes?.mime?.includes("video")

            return (
              <SwiperSlide key={index}>
                <Center
                  height={{ base: "30vh", md: "50vh", lg: "80vh" }}
                  width="100%"
                >
                  <Flex
                    width="100%"
                    height="100%"
                    gap={6}
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                  >
                    <Box overflow="hidden" width="100%">
                      {isVideo ? (
                        <video
                          src={url}
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "contain",
                          }}
                          controls
                        />
                      ) : (
                        <Image
                          objectFit="contain"
                          height="100%"
                          width="100%"
                          src={url}
                          alt={alt}
                        />
                      )}
                    </Box>
                    <Text
                      textAlign="center"
                      fontSize={{ base: "sm", xl: "md" }}
                      fontFamily="GTMaru!important"
                    >
                      {item.label}
                    </Text>
                  </Flex>
                </Center>
              </SwiperSlide>
            )
          })}
        </Swiper>
        <SwipperButton action="next" isPublicHome hide={!isDesktop} />
      </HStack>

      <HStack justifyContent="center" spacing={10} pt={3}>
        <SwipperButton action="prev" isPublicHome hide={isDesktop} />
        <SwipperButton action="next" isPublicHome hide={isDesktop} />
      </HStack>
    </Container>
  )
}
export default Carousel
