import client from "@/api/client"
import { SectionVisitRequest } from "@/typings/api"
import { useQuery } from "react-query"

const useActivityCount = (
  type: SectionVisitRequest["data"]["type"],
  groupId: string,
  updateLastSeen = true,
  isDisable = false
) => {
  const { data: response } = useQuery(
    [type, groupId],
    () =>
      client.sectionVisits.postSectionVisits({
        data: {
          type,
          group: groupId.toString(),
          ...(updateLastSeen && { last_seen: new Date().toISOString() }),
        },
      }),
    { enabled: !isDisable }
  )
  // @ts-ignore
  return response?.data?.count || 0
}

export default useActivityCount
