import { AddIcon } from "@chakra-ui/icons"
import { Button } from "@chakra-ui/react"
import Head from "next/head"
import Link from "next/link"
import { useTranslation } from "next-i18next"
import GroupList from "./Groups/GroupList"
import PageHeader from "./Layout/PageHeader"
import NewsList from "./News/NewsList"

const ConnectedHome = () => {
  const { t } = useTranslation()

  return (
    <>
      <Head>
        <title>{t("seo.home")}</title>
      </Head>
      <NewsList />
      <PageHeader
        title={t("group.title")}
        extraActions={
          <Button
            as={Link}
            size="sm"
            aria-label="groups"
            href="/groups/create"
            variant="brand"
            rightIcon={<AddIcon mt={1} boxSize="13px" />}
          >
            {t("group.create_button")}
          </Button>
        }
      />
      <GroupList />
    </>
  )
}

export default ConnectedHome
