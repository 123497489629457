import { SimpleGrid } from "@chakra-ui/react"
import GroupCardSkeleton from "./GroupCardSkeleton"

const GroupListSkeleton = () => {
  return (
    <SimpleGrid columns={{ sm: 1, md: 2, lg: 3 }} spacing={4} paddingY={4}>
      {Array.from(Array(6).keys()).map((el) => (
        <GroupCardSkeleton key={el} />
      ))}
    </SimpleGrid>
  )
}

export default GroupListSkeleton
