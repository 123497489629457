import { Box, BoxProps } from "@chakra-ui/react"

const ContainerPage = ({
  ...props
}: { children: React.ReactNode } & BoxProps) => {
  return (
    <Box
      maxWidth="container.lg"
      px={{ base: 4, md: 6 }}
      pt={{ base: 2, md: 6 }}
      {...props}
    />
  )
}

export default ContainerPage
