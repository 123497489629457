import { desktopThreshold } from "@/utils/theme"
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons"
import { Circle, useMediaQuery } from "@chakra-ui/react"

const SwipperButton = ({
  action,
  isPublicHome,
  hide,
}: {
  action: "next" | "prev"
  isPublicHome?: boolean
  hide?: boolean
}) => {
  const [isDesktop] = useMediaQuery(`(min-width: ${desktopThreshold})`)
  const propsBtn = {
    w: isDesktop ? 30 : 10,
    h: isDesktop ? 30 : 10,
    color: isPublicHome ? "white" : "black",
    border: isPublicHome ? "" : "1px black solid",
    rounded: "full",
  }

  return (
    <Circle
      size={isDesktop ? "70px" : "45px"}
      className={"image-swiper-button-" + action}
      bgColor={isPublicHome ? "black" : "white"}
      rounded="full"
      cursor="pointer"
      as="button"
      display={hide ? "none" : undefined}
    >
      {action === "next" ? (
        <ChevronRightIcon {...propsBtn} />
      ) : (
        <ChevronLeftIcon {...propsBtn} />
      )}
    </Circle>
  )
}

export default SwipperButton
