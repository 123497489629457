const BooksMd = (props: any) => {
  return (
    <svg
      width="594"
      height="243"
      viewBox="0 0 594 243"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <line
        x1="589.5"
        y1="238.5"
        x2="4.5"
        y2="238.5"
        stroke="black"
        strokeWidth="9"
        strokeLinecap="square"
      />
      <path d="M58.0461 35.6079H15V236.571H58.0461V35.6079Z" fill="black" />
      <path
        d="M104.061 71.3345H61.0146V236.571H104.061V71.3345Z"
        fill="black"
      />
      <path
        d="M302.964 53.4712H259.918V236.571H302.964V53.4712Z"
        fill="black"
      />
      <path
        d="M150.075 71.3345H107.029V236.571H150.075V71.3345Z"
        fill="black"
      />
      <path
        d="M176.795 90.6865H153.045V236.571H176.795V90.6865Z"
        fill="black"
      />
      <path d="M356.4 90.6865H332.65V236.571H356.4V90.6865Z" fill="black" />
      <path
        d="M203.512 71.3345H179.763V236.571H203.512V71.3345Z"
        fill="black"
      />
      <path
        d="M224.145 19.8505L61.4111 45.6992L65.1264 69.2238L227.861 43.3752L224.145 19.8505Z"
        fill="black"
      />
      <path
        d="M329.682 71.3345H305.933V236.571H329.682V71.3345Z"
        fill="black"
      />
      <path
        d="M256.949 49.0054H206.481V236.571H256.949V49.0054Z"
        fill="black"
      />
    </svg>
  )
}

export default BooksMd
