import useActivityCount from "@/hooks/useActivityCount"
import { GroupListResponseDataItem } from "@/typings/api"
import { Box, HStack, Text, VStack } from "@chakra-ui/react"
import Link from "next/link"
import NotificationBadge from "../Layout/NotificationBadge"
import GroupIllustration from "./GroupIllustration"
import GroupMembers from "./GroupMembers"
import GroupReviews from "./GroupReviews"
import React, { ReactNode } from "react"
import { useSession } from "next-auth/react"
import JoinGroupModal from "../Inputs/JoinGroupModal"
import { useTranslation } from "next-i18next"

const GroupListItem = ({ group }: { group: GroupListResponseDataItem }) => {
  const { attributes, id } = group
  const { t } = useTranslation()

  const { data: userData } = useSession()

  const isHalfPrivate = group.attributes?.type === "half-private"
  const isMember = group?.attributes?.memberships?.data?.some(
    (membership) =>
      membership?.attributes?.users_permissions_user?.data?.id ===
      Number(userData?.userId)
  )

  const groupReviewCount = useActivityCount(
    "GROUP_ACTIVITIES",
    id!.toString(),
    // Dont update last seen on group listing
    false,
    isHalfPrivate && !isMember
  )

  const Wrapper = ({ children }: { children: ReactNode }) => {
    return isMember ? (
      <Link href={"/groups/" + id}>{children}</Link>
    ) : (
      <JoinGroupModal group={group}>{children}</JoinGroupModal>
    )
  }

  return (
    <Wrapper>
      <VStack
        minH="200px"
        p={4}
        bg={isMember ? "white" : "gray.100"}
        cursor="pointer"
        boxShadow="lg"
        height="100%"
        justifyContent="space-between"
        alignItems="stretch"
        position="relative"
      >
        {groupReviewCount && (
          <NotificationBadge
            count={groupReviewCount}
            position="absolute"
            top={-2}
            right={4}
          />
        )}
        <Box borderBottom="2px solid black">
          <Text fontFamily="GTAlpina" fontSize="1.5rem">
            {attributes?.name}
          </Text>
        </Box>

        <GroupIllustration
          books={group?.attributes?.reviews?.data?.length ?? 0}
          isHalfPrivate={group?.attributes?.type === "half-private"}
        />

        <HStack justifyContent="space-between">
          <GroupMembers group={attributes} showNames hideModal />
          {group.attributes?.type === "half-private" && (
            <Text
              fontSize="xs"
              bg="primaryColor"
              fontFamily="GTMaru"
              padding={1}
              margin="auto"
              position="relative"
            >
              {t("group.half_private")}
            </Text>
          )}
          <GroupReviews books={attributes?.reviews?.data?.length ?? 0} />
        </HStack>
      </VStack>
    </Wrapper>
  )
}

export default GroupListItem
